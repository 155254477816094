// extracted by mini-css-extract-plugin
export var aEmpty = "oc";
export var aTitle = "pc";
export var pList = "qc";
export var aDetail = "rc";
export var aDate = "sc";
export var aArrow1 = "tc";
export var aArrow2 = "uc";
export var aText = "vc";
export var aInner = "wc";
export var aImage = "xc";
export var animationMarquee = "yc";