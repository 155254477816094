import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import * as css from "@css/projects/p-yutoyou-list.module.styl"
// import Tags from "@projects/Tags"
import { Image } from "@atoms/Image"
import { TransitionLink } from "../atoms/Link"

function YuToYouList({ data, empty }) {
    // data = []
    return (
        <div className={css.pList}>
            { data.length === 0 ?
                <div className={css.aEmpty}>
                    <h5 className={css.aTitle}>Empty</h5>
                    <p className={css.aText}>まだ投稿がありません。</p>
                </div>
                :
                <ul>
                    {data.map(({ node }, i) => {
                        let { thumbnail, title, date, databaseId } = node
                        return (
                            <li key={i}>
                                <TransitionLink className={css.aInner} to={`/member/yu-to-you/${databaseId}/`}>
                                    <figure className={css.aImage}>
                                        { thumbnail ?
                                            <Image data={thumbnail.node.localFile.childImageSharp} />
                                            :
                                            empty && <Image data={empty.childImageSharp} />
                                        }
                                    </figure>
                                    <div className={css.aDetail}>
                                        <p className={css.aTitle} dangerouslySetInnerHTML={{ __html: title }} />
                                        <p className={css.aDate}>{date}</p>
                                        <span className={css.aArrow2}></span>
                                    </div>
                                </TransitionLink>
                            </li>
                        )
                    })}
                </ul>
            }
        </div>
    )
}

export { YuToYouList }